<template>
  <div id="product">
    <el-dialog
      :title="productFormTitle"
      width="360px"
      :visible.sync="productDialogVisible"
      :close-on-click-modal="false"
      @close="productDialogClose"
    >
      <el-form
        ref="productFormRef"
        :model="productForm"
        :rules="productFormRules"
        label-position="right"
        label-width="100px"
      >
        <el-form-item label="产品名称" prop="productName">
          <el-input v-model="productForm.productName" placeholder="请输入产品名称" clearable />
        </el-form-item>
        <el-form-item label="规格型号" prop="spec">
          <el-input v-model="productForm.spec" placeholder="请输入规格型号" clearable />
        </el-form-item>
        <el-form-item label="类型" prop="type">
          <el-input v-model="productForm.type" placeholder="请输入类型" clearable />
        </el-form-item>
        <el-form-item label="单位" prop="unit">
          <el-input v-model="productForm.unit" placeholder="请输入单位" clearable />
        </el-form-item>
      </el-form>
      <div slot="footer">
        <el-button @click="productDialogVisible = false">
          取 消
        </el-button>
        <el-button type="primary" @click="productFormSubmit">
          确 定
        </el-button>
      </div>
    </el-dialog>
    <el-form inline size="small">
      <el-form-item label="产品名称">
        <el-input v-model="searchForm.productName" placeholder="请输入产品名称" clearable />
      </el-form-item>
      <el-form-item>
        <el-button type="primary" icon="el-icon-search" @click="handleSearch">
          搜索
        </el-button>
        <el-button type="primary" icon="el-icon-plus" @click="handleAdd">
          新增
        </el-button>
      </el-form-item>
    </el-form>
    <el-table
      :data="productPage.list"
      row-key="id"
      :cell-style="{padding: '6px'}"
      :header-cell-style="{background: '#f8f8f9'}"
      :height="getTableHeight()"
    >
      <el-table-column prop="productName" label="产品名称" />
      <el-table-column prop="spec" label="规格型号" />
      <el-table-column prop="type" label="类型" />
      <el-table-column prop="unit" label="单位" />
      <el-table-column label="操作" align="center">
        <template slot-scope="scope">
          <el-button
            type="text"
            icon="el-icon-delete"
            size="small"
            @click.stop="handleDelete(scope.$index, scope.row)"
          >
            删除
          </el-button>
          <el-button
            type="text"
            icon="el-icon-edit"
            size="small"
            @click.stop="handleUpdate(scope.$index, scope.row)"
          >
            修改
          </el-button>
          <el-button
            type="text"
            icon="el-icon-info"
            size="small"
            @click.stop="handleInfo(scope.$index, scope.row)"
          >
            详情
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      :total="productPage.total"
      :current-page="searchForm.pageNum"
      :page-size="searchForm.pageSize"
      :page-sizes="[10, 15, 20]"
      layout="total, sizes, prev, pager, next, jumper"
      background
      @current-change="pageNumChange"
      @size-change="pageSizeChange"
    />
  </div>
</template>

<script>
import { addProduct, deleteProduct, updateProduct, selectProductInfo, selectProductList } from '@/api/sale/product'

export default {
  data () {
    return {
      productDialogVisible: false,
      productFormTitle: '',
      productForm: {
        id: '',
        productName: '',
        spec: '',
        type: '',
        unit: ''
      },
      productFormRules: {
        productName: [{ required: true, message: '产品名称不能为空', trigger: ['blur', 'change']}]
      },
      productPage: {
        list: [],
        total: 0
      },
      searchForm: {
        pageNum: 1,
        pageSize: 10,
        productName: ''
      }
    }
  },
  created () {
    selectProductList(this.searchForm).then(res => {
      this.productPage = res
    })
  },
  methods: {
    productDialogClose () {
      this.$refs.productFormRef.resetFields()
    },
    productFormSubmit () {
      if (this.productFormTitle === '产品详情') {
        this.productDialogVisible = false
        return
      }
      this.$refs.productFormRef.validate(async valid => {
        if (valid) {
          if (this.productFormTitle === '新增产品') {
            await addProduct(this.productForm)
          } else if (this.productFormTitle === '修改产品') {
            await updateProduct(this.productForm)
          }
          this.productPage = await selectProductList(this.searchForm)
          this.productDialogVisible = false
        }
      })
    },
    handleAdd () {
      this.productFormTitle = '新增产品'
      this.productDialogVisible = true
    },
    handleDelete (index, row) {
      this.$confirm('确认删除？', '提示', {
        type: 'warning'
      }).then(async () => {
        await deleteProduct(row.id)
        if (this.productPage.list.length === 1 && this.searchForm.pageNum > 1) {
          this.searchForm.pageNum--
        }
        this.productPage = await selectProductList(this.searchForm)
      })
    },
    handleUpdate (index, row) {
      this.productFormTitle = '修改产品'
      this.productDialogVisible = true
      this.selectProductInfoById(row.id)
    },
    handleInfo (index, row) {
      this.productFormTitle = '产品详情'
      this.productDialogVisible = true
      this.selectProductInfoById(row.id)
    },
    selectProductInfoById (id) {
      selectProductInfo(id).then(res => {
        this.productForm.id = res.id
        this.productForm.productName = res.productName
        this.productForm.spec = res.spec
        this.productForm.type = res.type
        this.productForm.unit = res.unit
      })
    },
    handleSearch () {
      this.searchForm.pageNum = 1
      selectProductList(this.searchForm).then(res => {
        this.productPage = res
      })
    },
    pageNumChange (pageNum) {
      this.searchForm.pageNum = pageNum
      selectProductList(this.searchForm).then(res => {
        this.productPage = res
      })
    },
    pageSizeChange (pageSize) {
      this.searchForm.pageSize = pageSize
      this.searchForm.pageNum = 1
      selectProductList(this.searchForm).then(res => {
        this.productPage = res
      })
    }
  }
}
</script>

<style>
</style>
